import type { Locale } from "@i18next/utils";
import React from "react";
import { Swiper, SwiperSlide, type SwiperProps } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import type { SwiperModule } from "swiper/types";
import Picture from "@react/widget/Picture";
import Button from "@react/widget/Button";
import { H1 } from "@react/widget/Headline";

interface Props extends SwiperProps {
  locale: Locale;
  // data: Array<CaseStudyEntry>;
}

const HPHeroCarouselVisiual: React.FC<Props> = ({ locale, autoHeight = false, spaceBetween = 0, loop = true, ...props }) => {
  const modules: Array<SwiperModule> = [];
  if (!!props.pagination) {
    props.pagination = Object.assign({
      clickable: true
    }, (typeof props.pagination === "boolean") ? {}: props.pagination);
    modules.push(Pagination);
  }
  if (!!props.autoplay) {
    props.autoplay = Object.assign({
      delay: 3000,
      disableOnInteraction:	true,
      pauseOnMouseEnter: true,
      reverseDirection: false,
      stopOnLastSlide: false,
      waitForTransition: true,	
    }, (typeof props.autoplay === "object") ? props.autoplay: {});
    modules.push(Autoplay);
  }
  if (!!props.navigation)
    modules.push(Navigation);

  return (
    <Swiper
      className={""}
      autoHeight={autoHeight}
      spaceBetween={spaceBetween}
      loop={loop}
      modules={modules}
      {...props}
    >
      <SwiperSlide>
        <section className="py-0 h-full bg-purple md:bg-inherit relative">
          <div className="container mx-auto pt-0 md:pt-0 pb-0 md:pb-0 h-full">
            <div className="flex flex-col md:flex-row gap-6">
              <div className="hero-left w-full [&_button]:max-sm:w-full [&_button]:max-sm:justify-center [&_h2]:mt-1 [&_h2]:md:mt-4 [&_p]:md:text-xl [&_p]:mt-3 [&_p]:md:mt-6 [&_.desc]:md:text-xl [&_.desc]:mt-3 [&_.desc]:md:mt-6 pb-12 md:pb-20 md:w-1/2">
                <div className="pt-12 md:pt-20">
                  <H1>Step-by-Step Guide to Secure AI Adoption</H1>
                  <p className="mt-4 md:mt-8">Comprehensive guide to securely adopting Microsoft 365 Copilot, ensuring your organization's success with step-by-step instructions and best practices.</p>
                  <Button style="primary" size="lg" className="mt-8" href="https://www.avepoint.com/ebooks/copilot-for-microsoft-365-readiness-step-by-step-guide-sustainable-ai-adoption">Learn More</Button>
                </div>
              </div>
              <div className="hero-right relative w-full min-h-[220px] md:w-1/2 flex flex-col bg-bottom bg-no-repeat bg-contain sm:min-h-[320px] md:min-h-[440px]">
                <div className="absolute inset-0 z-[0]">
                  <Picture
                    className={"w-full h-full object-contain object-bottom"}
                    src="https://assets.avepoint.com/upload/images/home/Illustrations/Hero-banner_03_01.png"
                    alt="Step-by-Step Guide to Secure AI Adoption"
                    loading="eager"
                    fit="contain"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="background inset-0 z-[-1] flex justify-end absolute flex md:block bg-purple"></div>
        </section>
      </SwiperSlide>
      <SwiperSlide>
        <section className="py-0 h-full bg-green-herolight md:bg-inherit relative">
          <div className="container mx-auto pt-0 md:pt-0 pb-0 md:pb-0 h-full">
            <div className="flex flex-col md:flex-row gap-6">
              <div className="hero-left w-full [&_button]:max-sm:w-full [&_button]:max-sm:justify-center [&_h2]:mt-1 [&_h2]:md:mt-4 [&_p]:md:text-xl [&_p]:mt-3 [&_p]:md:mt-6 [&_.desc]:md:text-xl [&_.desc]:mt-3 [&_.desc]:md:mt-6 pb-12 md:pb-20 md:w-1/2">
                <div className="pt-12 md:pt-20">
                  <H1 size="3xl">Is Your Data Ready for AI Success?</H1>
                  <p className="mt-4 md:mt-8">Discover how effective data management boosts AI success, with insights from 750+ leaders across 16 countries and 10 industries, and practical recommendations.</p>
                  <Button style="primary" size="lg" className="mt-8" href="https://www.avepoint.com/shifthappens/reports/artificial-intelligence-and-information-management-report-2024">Learn More</Button>
                </div>
              </div>
              <div className="hero-right relative w-full min-h-[220px] md:w-1/2 flex flex-col bg-bottom bg-no-repeat bg-contain sm:min-h-[320px] md:min-h-[440px]">
                <div className="absolute inset-0 z-[0]">
                  <Picture
                    className={"w-full h-full object-contain object-bottom"}
                    src="https://assets.avepoint.com/upload/images/home/Illustrations/Hero-banner_01.avif"
                    alt="Is Your Data Ready for AI Success"
                    loading="eager"
                    fit="contain"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="background inset-0 z-[-1] flex justify-end absolute flex md:block bg-green-herolight">
            <Picture
              className={"w-full h-full"}
              src="https://assets.avepoint.com/upload/images/home/Illustrations/Hero-banner-bg1.jpg"
              width={2560}
              height={700}
              alt="hero background"
              loading="eager"
              fit="contain"
            />
          </div>
        </section>
      </SwiperSlide>
      <SwiperSlide>
        <section className="py-0 h-full bg-pink-herolight md:bg-inherit relative">
          <div className="container mx-auto pt-0 md:pt-0 pb-0 md:pb-0 h-full">
            <div className="flex flex-col md:flex-row gap-6">
              <div className="hero-left w-full [&_button]:max-sm:w-full [&_button]:max-sm:justify-center [&_h2]:mt-1 [&_h2]:md:mt-4 [&_p]:md:text-xl [&_p]:mt-3 [&_p]:md:mt-6 [&_.desc]:md:text-xl [&_.desc]:mt-3 [&_.desc]:md:mt-6 pb-12 md:pb-20 md:w-1/2">
                <div className="pt-12 md:pt-20">
                  <H1>Bridging the Data Gap for AI Success</H1>
                  <p className="mt-4 md:mt-8">Effective data management is crucial for AI success. The 2024 report reveals a gap in data readiness, with 52% facing quality issues. Generative AI heightens this need.</p>
                  <Button style="primary" size="lg" className="mt-8" href="https://www.avepoint.com/blog/manage/overcoming-the-data-readiness-gap-for-successful-ai-implementation">Learn More</Button>
                </div>
              </div>
              <div className="hero-right relative w-full min-h-[220px] md:w-1/2 flex flex-col bg-bottom bg-no-repeat bg-contain sm:min-h-[320px] md:min-h-[440px]">
                <div className="absolute inset-0 z-[0]">
                  <Picture
                    className={"w-full h-full object-contain object-bottom"}
                    src="https://assets.avepoint.com/upload/images/home/Illustrations/Hero-banner_02.avif"
                    alt="Bridging the Data Gap for AI Success"
                    loading="eager"
                    fit="contain"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="background inset-0 z-[-1] flex justify-end absolute flex md:block bg-pink-herolight"></div>
        </section>
      </SwiperSlide>
    </Swiper>
  );
};
export default HPHeroCarouselVisiual;